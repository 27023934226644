import localforage from 'localforage';
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import CustomsModal from '../components/CustomModal';
import { UserContext } from '../context/User.context';
import PngIcons from '../assets/images/pngIcons'
import { BusinessService } from "../services";
import { CircularProgress } from "@mui/material";
import SvgIcons from '../assets/images/svgIcons';


export default function Spend() {

    const location = useLocation();
    const history = useNavigate();
    const user = useContext(UserContext)
    const [success, setSuccess] = useState(false);
    const [passz, setPassz] = useState(null);
    const [spending, setSpending] = useState('$0')
    const [redeeming, setRedeeming] = useState('0')
    const [payloadz, setPayloadz] = useState(null)
    const [dealzz, setDealzz] = useState(null)
    const [publicPass, setPublicPass] = useState(null)
    const [amountprice, setAmountprice] = useState(false)
    const [refreshData, setRefreshData] = useState(false)
    const [selectedDeal, setSelectedDeal] = useState(null)
    const [brand, setBrand] = useState();
    const [checkoutLoader, setCheckoutLoader] = useState(false);
    const [openKeypad, setOpenKeypad] = useState(false);



    //NEW PAGE
    const [pass, setPass] = useState();
    const [deals, setDeals] = useState(null);
    const [pointRedeem, setPointRedeem] = useState(false);
    const [dataloader, setDataLoader] = useState(true);

    const onLoad1 = async () => {
        let pass = location?.state?.pass
        setPassz(pass);
        addData(location?.state);
        const brand = await BusinessService.GetPublicBrand({ query: { _id: location.state.payload.brandId } });
        if (brand && brand.response && brand.response.success) {
            setBrand(brand.response.data[0]);
        }
    }
    useEffect(() => {
        onLoad1();
    }, [])
    const addData = async (dd) => {
        let availableFlows = await localforage.getItem('flows');
        let pay = location.state.payload;
        const growthtool = await BusinessService.GetQueriedGrowthtool({ query: { _id: dd.pass.growthToolId } })
        if (growthtool && growthtool?.response && growthtool?.response?.success) {
            pay.flowIds = growthtool.response.data.flowId

            if (pay.passId) {
                const { response, error } = await BusinessService.GetPublicPass({ query: { _id: pay.passId, delete: false } });
                setPublicPass(response.data[0])
                // //console.log('response 22', response);
                let payload = { brandId: pay.brandId, passId: pay.passId, flowIds: pay.flowIds }
                //console.log('payload', payload);
                // const flowData = await BusinessService.GetFlows({ payload });
                // console.log("Flooo", flowData?.response);
                // if (flowData?.response?.success) {
                //     console.log('flowData?.response', flowData?.response);
                //     setDealzz(flowData.response.data)
                //     let tosendAgainflows = [];
                //     if (availableFlows?.length) {
                //         for (let index = 0; index < availableFlows.length; index++) {
                //             if ((availableFlows[index].dateStamp + 2629800000) < Date.now()) {
                //                 // //console.log('TimeOver');
                //             }
                //             else {
                //                 if (location?.state?.pass._id !== availableFlows[index].user._id) {
                //                     tosendAgainflows.push(availableFlows[index])
                //                 }
                //             }

                //         }
                //     }





                //     await localforage.setItem("flows", [
                //         ...tosendAgainflows,
                //         {
                //             user: location?.state?.pass,
                //             flow: location?.state?.payload,
                //             flows: flowData.response.data,
                //             dateStamp: new Date().valueOf()
                //         }]);
                // }
                // else {
                //     //console.log('checkError', flowData.response);

                // }
            }




            setPayloadz(pay)
            setRefreshData(false)
            // //console.log('payloadz', payloadz);
        }
    }
    const ConfirmSpendings = async () => {
        setCheckoutLoader(true)
        let user = await localforage.getItem('user');
        let updateFlows = await localforage.getItem('flows')

        let payload = {
            amount: spending.replace('$', ''),
            passId: passz._id,
            brandId: passz.brandId,
            type: 'amount',
            platform: 'bc web',
            // amount: spending.replace('$', ''),
            // discountType: 1,
            // discountValue: 0,
            // flowId: location.state.payload.flowIds,
            // idKey: user.idKey,
            // passCode: passz.passCode,
            // transactionType: 'amount',
        }

        const { error, response } = await BusinessService.BusinessCheckout({ payload })

        if (response && response.success) {
            console.log("Pointss", response)
            console.log("Current spending points", updateFlows)

            if (response?.data?.loyaltyCard?.points) {

                for (let idx = 0; idx < updateFlows?.length; idx++) {
                    updateFlows[idx].user.loyaltyCard.currentPoints += response.data.loyaltyCard.points;
                }
            }
            setSuccess(true);

            // document.getElementsByClassName('fs-88Imp ccSace')[0].value = ""
            await localforage.setItem("flows", updateFlows);
            setSpending('$0');
            setTimeout(() => {
                setSuccess(!success); history('/scanner')
            }, 2000)

        }

        setCheckoutLoader(false);
        // history('/scanner')

    }
    const handleDealSelect = (deal) => {
        console.log('zoop deal', deal);
        if (selectedDeal && selectedDeal.id == deal.id) {
            setSelectedDeal(null)
        } else {
            setSelectedDeal(deal)
        }
    }
    const refresh = () => {
        setRefreshData(true)
        onLoad()
    }
    //ANOTHER P{AGE}
    const onLoad = async () => {
        if (!location.state.pass) {
            const { response, error } = await BusinessService.GetPublicPass({ query: { _id: location.state.payload.passId, delete: false } });
            //console.log('response payLoad', response);
            if (response && response.success) {
                setPass(response.data[0])
            }
        } else {
            setPass(location?.state?.pass)
        }

        if (location.state.deal) {
            setDeals(location.state.deal)
            setPass(location.state.pass)

        } else {
            setDataLoader(true);
            if (location && location.state && location.state.payload) {

                let payload = {
                    brandId: location.state.payload.brandId,
                    passId: location.state.payload.passId,
                    flowIds: location.state.payload.flowIds
                }
                //console.log('payload', payload);
                // const flowData = await BusinessService.GetFlows({ payload });
                // //console.log(flowData);

                // if (flowData.response && flowData.response.success) {
                //     // for (const iterator of flowData.response.data) {
                //     //     if(iterator?.ExpiryData && iterator?.dealExpiration){
                //     //         if(iterator?.ExpiryData && iterator?.dealExpiration)
                //     //         ((+iterator.ExpiryData) + (+iterator?.dealExpiration)) > new Date().valueOf()
                //     //     }
                //     // }
                //     let data = [...flowData.response.data]
                //     for (let index = 0; index < data.length; index++) {
                //         if (data[index]?.ExpiryData && data[index]?.dealExpiration) {
                //             if (new Date(data[index].ExpiryData).valueOf() + Number(data[index]?.dealExpiration) > new Date().valueOf()) {
                //                 data[index].valid = false
                //             }

                //         }

                //     }
                //     data.sort((a, b) => {
                //         if (a.valid === b.valid) {
                //             return 0;
                //         }

                //         if (a.valid === true) {
                //             return -1;
                //         }

                //         return 1;
                //     });
                //     setDeals(data);

                //     setDataLoader(false);
                // }
                setDataLoader(false);
                setRefreshData(false)
            }
        }


    }
    useEffect(() => {
        console.log('location', location);
        if (location.state == null) {
            history('/scanner', { replace: true })
        }
        else {
            onLoad();
        }
    }, []);
    const pointsRedemmedSubmit = async () => {
        setCheckoutLoader(true)
        let payload = {
            passId: passz._id,
            brandId: passz.brandId,
            location: {
                lat: brand?.location.lat,
                address: brand?.location.address,
                lng: brand?.location.lng
            },
            removePoints: redeeming,
            type : 'redeem points',
            platform : 'bc web'
        }

        const { response, error } = await BusinessService.BusinessCheckout({ payload });
        // const { response, error } = await BusinessService.SingleLoyalitySelf({ payload });

        if (response && response.success) {
            setSuccess(true);
            setCheckoutLoader(false)
            setTimeout(() => {
                setSuccess(!success); history('/scanner')
            }, 2000)
        }

        setPointRedeem(false);

    }
    const handleTransaction = async (amount) => {
        // setLoader(true)
        setCheckoutLoader(true)
        const user = await localforage.getItem('user');

        let payload = {
            // amount: amount,
            // availDealOffer: true,
            // passCode: passz.passCode,
            // dealOffer: {
            //     name: selectedDeal?.deal,
            //     isFromBusinessConnector: '1',
            //     _id: selectedDeal?.id,
            //     image: selectedDeal?.image,
            //     description: selectedDeal?.description,
            //     condition: selectedDeal?.condition
            // },
            // discountType: 1,
            // discountValue: 0,
            // flowId: location.state.payload.flowIds,
            // idKey: user.idKey,
            // location: {
            //     lat: brand?.location.lat,
            //     address: brand?.location.address,
            //     lng: brand?.location.lng
            // },
            // pointUsed: (selectedDeal?.condition[0].condition === 'point') ? selectedDeal?.condition[0].conditionValue[1] : 0,
            // transactionType: 'giveaway',
            amount: amount,
            passId: passz._id,
            brandId: passz.brandId,
            data:{
                dealOffer: {
                    name: selectedDeal?.deal,
                    isFromBusinessConnector: '1',
                    _id: selectedDeal?.id,
                    image: selectedDeal?.image,
                    description: selectedDeal?.description,
                    condition: selectedDeal?.condition
                },
            },
            dealId: selectedDeal?.id,
            location: {
                lat: brand?.location.lat,
                address: brand?.location.address,
                lng: brand?.location.lng
            },
            removePoints: (selectedDeal?.condition[0].condition === 'point') ? selectedDeal?.condition[0].conditionValue[1] : 0,
            type : 'deal',
            platform : 'bc web',
        }

        console.log('transition payload',payload)
        const { error, response } = await BusinessService.BusinessCheckout({ payload });


        if (response && response.success) {
            console.log('responseresponse', response);
            nextWorking(response)
            setSuccess(true);
            setTimeout(() => {
                setSuccess(!success); history('/scanner')
            }, 2000)
        }
        setCheckoutLoader(false)
    }
    const handleTransactionZoop = async (amount) => {
        // setLoader(true)
        setCheckoutLoader(true)
        const user = await localforage.getItem('user');
        let payload = {
            amount: 0,
            passId: passz._id,
            brandId: passz.brandId,
            dealId: selectedDeal?.id,
            data:{
                dealOffer: {
                    name: selectedDeal?.deal,
                    isFromBusinessConnector: '1',
                    _id: selectedDeal?.id,
                    image: selectedDeal?.image,
                    description: selectedDeal?.description,
                    condition: selectedDeal?.condition
                },
            },
            location: {
                lat: brand?.location.lat,
                address: brand?.location.address,
                lng: brand?.location.lng
            },
            removePoints: (selectedDeal?.condition[0].condition === 'point') ? selectedDeal?.condition[0].conditionValue[1] : 0,
            type : 'deal',
            platform : 'bc web',
            // amount: '0',
            // availDealOffer: true,
            // passCode: passz.passCode,
            // dealOffer: {
            //     name: selectedDeal?.deal,
            //     isFromBusinessConnector: '1',
            //     _id: selectedDeal?.id,
            //     image: selectedDeal?.image,
            //     description: selectedDeal?.description,
            //     condition: selectedDeal?.condition
            // },
            // discountType: 1,
            // discountValue: 0,
            // flowId: location.state.payload.flowIds,
            // idKey: user.idKey,
            // location: {
            //     lat: brand?.location.lat,
            //     address: brand?.location.address,
            //     lng: brand?.location.lng
            // },
            // pointUsed: (selectedDeal?.condition[0].condition === 'point') ? selectedDeal?.condition[0].conditionValue[1] : 0,
            // transactionType: 'giveaway',
        }
        const { error, response } = await BusinessService.BusinessCheckout({ payload });


        if (response && response.success) {
            console.log('responseresponse', response);
            nextWorking(response)

        }
        let Zpayload = {
            // location: {
            //     address: 'Karachi, Pakistan',
            //     lat: 24.91123372672892,
            //     lng: 67.08654837127668
            // },
            // brandId: passz.brandId,
            // passId: passz._id,
            // subtract: true,
            // points: redeeming


            passId: passz._id,
            brandId: passz.brandId,
            location: {
                lat: brand?.location.lat,
                address: brand?.location.address,
                lng: brand?.location.lng
            },
            removePoints: redeeming,
            type : 'redeem points',
            platform : 'bc web'
        }

        // const SingleLoyalitySelf = await BusinessService.SingleLoyalitySelf({ payload: Zpayload });
        const SingleLoyalitySelf = await BusinessService.BusinessCheckout({ payload: Zpayload });
        if (SingleLoyalitySelf.error) {
            console.log('eerror', Zpayload, SingleLoyalitySelf.error)
        }
        if (SingleLoyalitySelf.response && SingleLoyalitySelf.response.success) {
            setSuccess(true);
            setCheckoutLoader(false)
            setTimeout(() => {
                setSuccess(!success); history('/scanner')
            }, 2000)
        }

        setCheckoutLoader(false)
    }
    const nextWorking = async (response) => {
        if (response?.success) {
            const availableFlows = await localforage.getItem('flows');
            let payload = { brandId: response.data.brandId, passId: response.data.passId, flowIds: location.state.payload.flowIds }
            //console.log('payload', payload);
            // const flowData = await BusinessService.GetFlows({ payload });
            // if (flowData.response.success) {
            //     for (let index = 0; index < availableFlows.length; index++) {
            //         if ((availableFlows[index].flow.brandId == response.data.brandId) && (availableFlows[index].flow.passId == response.data.passId)) {
            //             availableFlows[index].flows = flowData?.response?.data
            //         }
            //         await localforage.setItem("flows", availableFlows);
            //     }
            // }
        }
    }
    const CheckOutMain = () => {

        if (!amountprice && +spending.replace('$', '') > 0 && !selectedDeal) {
            ConfirmSpendings()
        }
        if (amountprice && +redeeming > 0 && !selectedDeal) {
            pointsRedemmedSubmit()
        }
        if (selectedDeal && (+spending.replace('$', '') == 0 || +spending.replace('$', '') == '') && (+redeeming == 0 || redeeming == '')) {
            handleTransaction('0')
        }
        if (selectedDeal && !amountprice && +spending.replace('$', '') > 0) {
            handleTransaction(spending.replace('$', ''))
        }
        if (selectedDeal && amountprice && +redeeming > 0) {
            handleTransactionZoop()
        }
    }
    const settingTheSpending = (e) => {
        console.log('target', e.target.value);
        if (e.target.value.length >= 1) setSpending(e.target.value)
    }
console.log('deals',deals)
console.log('selecetdDeal',selectedDeal)

    let KeyBoardKeys = [
        {
            "key": 1,
            "data": 1,
            'width': false
        },
        {
            "key": 2,
            "data": 2,
            'width': false
        },
        {
            "key": 3,
            "data": 3,
            'width': false
        },
        {
            "key": 4,
            "data": 4,
            'width': false
        },
        {
            "key": 5,
            "data": 5,
            'width': false
        },
        {
            "key": 6,
            "data": 6,
            'width': false
        },
        {
            "key": 7,
            "data": 7,
            'width': false
        },
        {
            "key": 8,
            "data": 8,
            'width': false
        },
        {
            "key": 9,
            "data": 9
        },
        {
            "key": <SvgIcons.hideKeyboard />,
            "data": "close",
            'width': true
        },
        {
            "key": '.',
            "data": '.',
            'width': true
        },
        {
            "key": 0,
            "data": 0,
            'width': true
        },

        {
            "key": <SvgIcons.deleteOne />,
            "data": "delete",
            'width': true
        }
    ]


    const setKey = (key) => {
        if (key !== 'close' && key !== 'delete') {
            if (spending == '$0' && !amountprice) {
                let newSpending = '$' + key
                setSpending(newSpending)
            }
            else if (!amountprice) {
                let newSpending = spending + key
                setSpending(newSpending)
            }
            else if (redeeming !== '0' && amountprice) {
                let newSpending = `${redeeming}` + key
                setRedeeming(newSpending)
            }
            else if (redeeming == '0' && amountprice) {
                let newSpending = key
                setRedeeming(newSpending)
            }
        }
        if (key == 'delete' && spending !== '$' && !amountprice) {
            let newString = spending.slice(0, -1);
            setSpending(newString)
        }
        if (key == 'delete' && spending !== '$' && amountprice) {
            let newString = redeeming.slice(0, -1);
            setRedeeming(newString)
        }
        if (key == 'close') setOpenKeypad(false)

    }
    useEffect(() => {

        console.log('spending',spending);
        var spendingCount = (spending.match(/\./g) || []).length;
        if (spendingCount > 1) {
            let arrayA = spending.split('');
            let lastDotIndex = arrayA.lastIndexOf('.');
            arrayA.splice(lastDotIndex, 1);
            let result = arrayA.join('');
            setSpending(result)
        }
        console.log('redeming',redeeming);
        var redeemingCount = (redeeming.toString().match(/\./g) || []).length;
      

        if (redeemingCount > 1) {
            let arrayB = redeeming.split('');
            let lastDotIndexB = arrayB.lastIndexOf('.');
            arrayB.splice(lastDotIndexB, 1);
            let resultB = arrayB.join('');
            setRedeeming(resultB)
        }

    }, [redeeming, spending])


    useEffect(() => {
        if (passz && !selectedDeal && redeeming > passz[passz.passType].currentPoints) {
            setRedeeming(`${passz[passz.passType].currentPoints }`  )
        }
        if (passz && selectedDeal && (+redeeming  + selectedDeal.subtract) > passz[passz.passType].currentPoints) {
            setRedeeming(`${passz[passz.passType].currentPoints -  selectedDeal.subtract}`  )
        }
    }, [redeeming,selectedDeal])






    return (
        <>
            <div id='spend' className='w-100' style={{ height: '100vh', }}>
                <div className='pb_15' style={{ width: '100%', backgroundColor: '#FBFBFB' }}>
                    <div className='w-90 pt_34' style={{ display: 'flex', gap: '70px', justifyContent: 'space-between', alignItems: 'center', margin: '0 auto' }}>
                        <div>
                            <div style={{ display: 'flex', alignItems: 'center', }}>
                                <div className='customer-logo-Image'><img src={PngIcons.Man} /></div>
                                <div className='customer-info'>
                                    
                                    <div className='customer-info-name'>{passz && passz.userData.firstName} {passz && passz.userData.lastName} </div>
                                    <div className='customer-info-email'>{passz && passz.userData.email}</div>
                                </div>
                            </div>

                        </div>
                        {/* <img src={PngIcons.CloseRounded} alt='image' onClick={() => history('/scanner')} width={32} height={32} style={{ cursor: 'pointer' }} /> */}
                        <span className='home_back' onClick={() => history('/scanner')}>
                            <SvgIcons.home /> Back to Home
                        </span>
                    </div>



                    <div className='w-90 bigInput' >
                        <div className='main-points-amount'>
                            <div onClick={() => setAmountprice(false)} className={!amountprice && 'amount-points-selected'}>Amount</div>
                            <div onClick={() => setAmountprice(true)} className={amountprice && 'amount-points-selected'}>Points</div>
                        </div>
                        <div className='d-flex w-70 align-items-center ' style={{ borderBottom: '2px solid #DDD', }} >
                            <input readOnly={openKeypad} value={!amountprice ? spending : redeeming} onChange={(e) => { !amountprice ? settingTheSpending(e) : setRedeeming(e.target.value) }} className='fs-88Imp ccSace res-spend first-blackLetter w-100' style={{ background: 'transparent', color: '#484951', border: 'none', outline: 'none', width: 'fit-content' }} />
                            <div className='cp' onClick={() => { setOpenKeypad(!openKeypad) }}><SvgIcons.Keyboard /></div>
                        </div>
                        <div className='available-points'>
                            Available Points: <div>{passz && passz[passz.passType].currentPoints}</div>
                        </div>
                    </div>
                </div>

                {!openKeypad &&
                    <div>
                        <div className='deals mt-50'>
                            <div style={{ paddingLeft: '16px', paddingRight: '16px', paddingBottom: '11px', color: '#60606E' }} className='headingdeals  text-left d-flex justify-content-space-between'>

                                {deals && deals.length == 0 ? "No Deals Available" : "Available Deals"}
                                <div onClick={() => refresh()} style={{ width: '16px', height: '16px', cursor: 'pointer' }}>
                                    <img className={refreshData ? 'rotate-infinite' : ''} style={{ width: '100%' }} src={PngIcons.Refresh} />
                                </div></div>
                            {dataloader ?
                                <div className='loader'>
                                    <div className="spinner-border" role="status">
                                        <span class="sr-only"></span>
                                    </div>
                                </div>

                                : deals && deals.length > 0 && deals.map((deal, idx) => (
                                    <div className={!deal.valid ? 'deal invalid' : (selectedDeal && selectedDeal?.id == deal?.id) ? 'selected-deal deal' : 'deal'} 
                                    onClick={() =>  {
                                        if (!deal.cwCode)  {
                                            handleDealSelect(deal) }}
                                            
                                        }
                                        >
                                        <div className='colm1'>
                                            <div className='HeadlineH20M'>
                                                {deal.deal}
                                            </div>
                                            <div className='caption'>
                                                {deal.description}
                                            </div>
                                            <div className='condition'>
                                                {/* {deal.condition[0]?.conditionValue?.length > 1 ? deal.condition[0].conditionValue[1] + ' ' + deal.condition[0].condition : deal.condition[0].condition} */}
                                                {console.log('1stlog',deal)}
                                                { typeof deal.condition == 'string' ? deal.condition + ' ' + deal.conditionValue : (deal.condition[0].conditionValue?.length>0&&deal.condition[0].conditionValue[1]) ? deal.condition[0].conditionValue[1] + ' ' + deal.condition[0].condition : deal.condition[0].condition}
                                                
                                            </div>
                                        </div>
                                        <div className='colm2'>
                                            <img className='dealimage' src={deal.image} width={'100px'} height={'100px'} />
                                        </div>
                                    </div>
                                ))
                            }

                        </div>
                    </div>}






                <div className='w-100 ' style={{
                    position: 'absolute',
                    bottom: '1px',
                    backgroundColor: '#DDD'
                }}>
                    <div className='w-100 '
                    >
                        <div className={
                            'w-100 res-continue'
                        } onClick={() => CheckOutMain()} style={{
                            background: "#1059FA",
                            height: "72px", display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', margin: '0 auto', marginBottom: '5px'
                        }}>
                            <h2 className='fs-16' onClick={() => { CheckOutMain() }} style={{ color: " #FFF" }}>{checkoutLoader ? <CircularProgress size={30} color='inherit' /> : 'Checkout'}</h2>
                        </div>
                    </div>

                    {openKeypad && <div
                        style={{
                            height: openKeypad ? '40%' : '0px',

                        }}
                        className='KeyBoard w-100'>
                        <div className='p_btns'>
                            {
                                KeyBoardKeys.map((ele, idx) => {
                                    return <div onClick={() => setKey(ele.data)} style={{ background: ele.data == 'close' ? '#60606E' : '#FFF', width: ele.width ? '24.75%' : '33%' }} className='k_btn'>{ele.key}</div>
                                })
                            }

                        </div>

                    </div>}
                </div>




                {/* <div className='w-100 '
                    style={{
                        position: 'absolute', bottom: '5px'
                    }}>
                    <div className={
                        'w-70 res-continue'
                    } onClick={() => CheckOutMain()} style={{
                        background: "#1059FA",
                        borderRadius: "6px", height: "72px", display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', margin: '0 auto'
                    }}>
                        <h2 className='fs-16' onClick={() => { CheckOutMain() }} style={{ color: " #FFF" }}>{checkoutLoader ? <CircularProgress size={30} color='inherit' /> : 'Checkout'}</h2>
                    </div>
                </div> */}
            </div>

            {

                success && <CustomsModal
                    open={true}
                    component={<SuccessModal
                        success={success}
                        setSuccess={setSuccess}
                        history={history}
                    />}
                    onClose={() => { setSuccess(!success) }}
                    minWidth={'498px'}
                />

            }
        </>

    )
}
function SuccessModal({ success, setSuccess, history }) {

    return (
        <div className='spendingModal'>
            <div className='right'>
                <svg width="78" height="78" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M44 0C19.8 0 0 19.8 0 44C0 68.2 19.8 88 44 88C68.2 88 88 68.2 88 44C88 19.8 68.2 0 44 0ZM64.9 35.5667L41.0667 57.5667C39.2333 59.0333 36.6667 59.0333 35.2 57.5667L23.4667 46.9333C22 45.4667 22 43.2667 23.1 41.8C24.2 40.3333 26.7667 40.3333 28.2333 41.4333L38.1333 50.2333L60.1333 30.0667C61.6 28.6 63.8 28.9667 65.2667 30.4333C66.7333 31.9 66.3667 34.4667 64.9 35.5667Z" fill="#1059FA" />
                </svg>
            </div>

            <div className='headsuccess'>Checkout
                Successfully Completed!</div>


            <button className='successBtn' onClick={() => { setSuccess(!success); history('/scanner') }}>
                Done
            </button>
        </div>
    )
}
