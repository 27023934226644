import { useState, useEffect, useContext } from 'react'
import PngIcons from '../assets/images/pngIcons'
import localforage from 'localforage'
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../context/User.context'
import CustomsModal from '../components/CustomModal';
import { BusinessService } from '../services';
import env from '../config'

const ReadFlowData = () => {
    const user = useContext(UserContext);
    const [list, setList] = useState()
    const [current, setCurrent] = useState()
    const [currentIndex, setCurrentIndex] = useState(1)
    const [pointRedeem, setPointRedeem] = useState(false);
    const [success, setSuccess] = useState(false);
    const [points, setPoints] = useState();
    const [pointsLoader, setPointsLoader] = useState(false);
    const [up, setUp] = useState(false)
    const [currentData, setCurrentData] = useState()

    const history = useNavigate();

    const pointsRedemmedSubmit = async () => {
        let availableFlows = await localforage.getItem('flows');

        setPointsLoader(true);
        let payload = {
            // location: {
            //     address: 'Karachi, Pakistan',
            //     lat: 24.91123372672892,
            //     lng: 67.08654837127668
            // },
            // brandId: list[currentIndex - 1].flow.brandId,
            // passId: list[currentIndex - 1].flow.passId,
            // subtract: true,
            // points: points

            passId: list[currentIndex - 1].flow.passId,
            brandId: list[currentIndex - 1].flow.brandId,
            // location: {
            //     lat: brand?.location.lat,
            //     address: brand?.location.address,
            //     lng: brand?.location.lng
            // },
            removePoints: points,
            type : 'redeem points',
            platform : 'bc web'
        }

        const { response, error } = await BusinessService.BusinessCheckout({ payload });
        // const { response, error } = await BusinessService.SingleLoyalitySelf({ payload });

        if (response && response.success) {
            console.log('response', response);
            console.log('availableFlows', availableFlows);
            let updated;

            for (let index = 0; index < availableFlows.length; index++) {
                if ((availableFlows[index].flow.brandId == response.data.transaction.brandId) && (availableFlows[index].flow.passId == response.data.transaction.passId)) {
                    availableFlows[index].user.loyaltyCard.currentPoints= response.data.transaction.loyaltyCard.passData.currentPoints
                //     if(availableFlows[index].user.loyaltyCard.currentPoints !=0){
                //     availableFlows[index].user.loyaltyCard.currentPoints -= response.data.transaction.loyaltyCard.pointUsed
                // }
                
                }
            }

            await localforage.setItem("flows", availableFlows);

            setUp(true)
            setSuccess(true);
            setPointsLoader(false);
        }

        setPointRedeem(false);

    }


    const onLoad = async () => {
        const flows = await localforage.getItem('flows');
        if (flows) {
            setList(flows)
        }

        if (up !== false) {
            setUp(false)
        }
        
    }

    useEffect(() => {
        onLoad();
        // refreshMyFlows();
    }, [up])
    //console.log('currentIndex', currentIndex);

    const refreshMyFlows = async () => {

        // pointsRedemmedSubmit();

        let availableFlows = await localforage.getItem('flows');


        let payload = { brandId: list[currentIndex - 1].flow.brandId, passId: list[currentIndex - 1].flow.passId, flowIds: list[currentIndex - 1].flow.flowIds }
        //console.log('payload', payload);
        // const flowData = await BusinessService.GetFlows({ payload });
        // if (flowData?.response?.success) {
        //     console.log("Read Flow",flowData?.response);

        //     for (let index = 0; index < availableFlows.length; index++) {
        //         if ((availableFlows[index].flow.brandId == list[currentIndex - 1].flow.brandId) && (availableFlows[index].flow.passId == list[currentIndex - 1].flow.passId)) {
        //             availableFlows[index].flows = flowData?.response?.data
        //         }
        //     }

        //     await localforage.setItem("flows", availableFlows);
        // }

        // const secondFlows = await localforage.getItem("flows");
        // const { responsee, error } = await BusinessService.SingleLoyalitySelf({ payload });
        // if (responsee && responsee.success) {
        //     console.log('responsee', responsee);
        //     console.log('availableFlows', secondFlows);
        //     let updated;

        //     for (let index = 0; index < secondFlows.length; index++) {
        //         if ((availableFlows[index].flow.brandId == responsee.data.transaction.brandId) && (availableFlows[index].flow.passId == responsee.data.transaction.passId)) {
        //             secondFlows[index].user.loyaltyCard.currentPoints = responsee.data.transaction.loyaltyCard.passData.currentPoints
       
        //         }
        //     }

        //     await localforage.setItem("flows", secondFlows);
        // }

        setUp(true)

    }



    const refreshFlows = async ()=>{

        let payload = { 
            brandId: list[currentIndex - 1].flow.brandId, 
            passId: list[currentIndex - 1].flow.passId, 
            flowIds: list[currentIndex - 1].flow.flowIds
         }
         const secondFlows = await localforage.getItem("flows");
        const { responsee, error } = await BusinessService.SingleLoyalitySelf({ payload });
        if (responsee && responsee.success) {
            console.log('responsee', responsee);
            console.log('availableFlows', secondFlows);
            // let updated;

            for (let index = 0; index < secondFlows.length; index++) {
                if ((secondFlows[index].flow.brandId == responsee.data.transaction.brandId) && (secondFlows[index].flow.passId == responsee.data.transaction.passId)) {
                    secondFlows[index].user.loyaltyCard.currentPoints = responsee.data.transaction.loyaltyCard.passData.currentPoints
       
                }
            }

            await localforage.setItem("flows", secondFlows);
        }

    }


    const handleUserSearch = (e) => {

        let data = list.filter((element, idx) => {
            if ((element.user.userData.email.toLowerCase().includes(e.target.value.toLowerCase()) || (element.user.userData.firstName && element.user.userData.firstName.includes(e.target.value.toLowerCase()))  || (element.user.userData.lasttName && element.user.userData.lasttName.includes(e.target.value.toLowerCase()))))
                return true;
        });
        setCurrentData(data);
        //console.log('data',data);
    }


    return (



        <div id='MainReadFlowDataPage'>
            <div className='w-95 MainReadFlowDataPageFirst blue-nav plr-res'>
                {
                    //console.log('list', list)
                }
                <div className=' body-font for-white'>Logged in by:</div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h5 className='capitalize for-white'>{user && user.firstName + ' ' + user.lastName}</h5>
                    <div className='econs' style={{ display: 'flex', justifyContent: 'space-between',alignItems:'center' }}>
                        <div style={{ marginRight: '8px' }}> <img
                            className='for-white scanner-white'
                            src={PngIcons.ScannerW}
                            width= '23px'
                            height = '23px'
                            alt=''
                            style={{cursor : 'pointer'}}
                            onClick={() => history('/scanner')}
                        />
                             <img
                            className='for-white scanner-black'
                            src={PngIcons.ScannerB}
                            alt=''
                            width="28px"
                            height="28px"
                            style={{cursor : 'pointer'}}
                            onClick={() => history('/scanner')}
                        />
                        </div>
                        <div >
                            <img
                                className='read-login-black'
                                src={PngIcons.Bell}
                                alt=''
                                width="28px"
                                height="28px"
                                style={{cursor : 'pointer'}}
                            // onClick={onClickHandler}
                            />
                            <img
                                className='read-login-white'
                                src={PngIcons.PowerWhite}
                                alt=''

                                style={{cursor : 'pointer'}}
                            // onClick={onClickHandler}
                            />
                        </div>

                    </div>
                </div>

            </div>
            <div className='w-95 ' id='ReadFlow' >
                <div className='w-30 res-search-div' style={{ height: '100%' }}>
                    <div style={{ heihgt: "20%" }} className='res-search-div1'>
                        <p className='lablSearch forleft-pad' > Search Customer</p>
                        <div ><input className='customerSearch w-100' onChange={(e) => handleUserSearch(e)} type='text' placeholder='Type here to search customer' /></div></div>
                    <div className='customerMain' >

                        {
                            currentData ? currentData.map((value, index) => (
                                <>
                                    <div className='customerOne' onClick={() => setCurrentIndex(index + 1)}>
                                        <div className='customerOneImage '>
                                            <div>
                                                <img src={PngIcons.Man} alt='img' />
                                            </div>
                                        </div>
                                        <div className='customerOneContent'>
                                            <div className='customerOneContentName'>{value?.user?.userData?.firstName} {value?.user?.userData?.lastName}</div>
                                            <div className='customerOneContentEmail'>{value?.user?.userData?.email}</div>
                                        </div>
                                        <div className='customerOnePoints'>{value?.user?.loyaltyCard?.currentPoints}</div>

                                    </div>
                                </>
                            )) : list && list.map((value, index) => (
                                <>
                                    <div className='customerOne' onClick={() => setCurrentIndex(index + 1)}>
                                        <div className='customerOneImage '>
                                            <div>
                                                <img src={PngIcons.Man} alt='img' />
                                            </div>
                                        </div>
                                        <div className='customerOneContent'>
                                            <div className='customerOneContentName'>{value?.user?.userData?.firstName} {value?.user?.userData?.lastName}</div>
                                            <div className='customerOneContentEmail'>{value?.user?.userData?.email}</div>
                                        </div>
                                        <div className='customerOnePoints'>{value?.user?.loyaltyCard?.currentPoints}</div>

                                    </div>
                                </>
                            ))



                        }
                    </div>
                </div>
                <div className='w-75 oneCustomerMain'>
                    <div className='oneCustomerMainSubOne'>
                        {(list?.length && currentIndex) &&
                            <div className='oneCustomerMainSubOneChild'>
                                <div className='oneCustomerMainSubOneChildOne'>
                                    <div className='oneCustomerMainSubOneChildOneImage'>
                                        <img src={PngIcons.Man} alt='img' />
                                    </div>
                                    <div className='oneCustomerMainSubOneChildOneInfo'>
                                        <h2>{list[currentIndex - 1].user.userData.firstName} {list[currentIndex - 1].user.userData.lastName}</h2>
                                        <h3>{list[currentIndex - 1].user.userData.email}</h3>
                                        <p>{list[currentIndex - 1].user.passCode} </p>
                                    </div>

                                </div>
                                <div className='oneCustomerMainSubOneChildTwo'>
                                    <div>{list[currentIndex - 1].user.loyaltyCard.currentPoints}
                                    </div>
                                    <img src={PngIcons.Refresh} onClick={() => {refreshMyFlows()}} width={'17px'} height={'17px'} alt='img' />
                                </div>
                            </div>}
                    </div>
                    
                    <div className='oneCustomerMainSubTwo'>
                    
                        <button className='bt-res' onClick={() => { history('/spend', { state: { pass: list[currentIndex - 1].user, payload: list[currentIndex - 1].flow } }) }} > Enter Spending</button>
                        <button className='bt-res' onClick={() => setPointRedeem(true)}>Points Redeem</button>
                    
                    </div>

                    <div className='oneCustomerMainSubThree'>Available Deals</div>
                    <div className='oneCustomerMainSubFour'>
                        <div className='w-100'>

                            {
                                (list?.length && currentIndex) && list[currentIndex - 1].flows.map((deal, idx) => (
                                    <div style={{cursor : 'pointer'}} className={deal.valid ? 'dealMain' : 'dealMain disabled'} onClick={()=>history('/deal',{state :{deal : deal , payload :{brandId: list[currentIndex - 1].flow.brandId, passId: list[currentIndex - 1].flow.passId, flowIds: list[currentIndex - 1].flow.flowIds }}})} >
                                        <div className=''>
                                            <div className='HeadlineH20M'>
                                                {deal.deal}
                                            </div>
                                            <div className='caption'>
                                                {deal.description}

                                            </div>
                                            <div className='condition'>
                                               {deal.condition[0].conditionValue[deal.condition[0].conditionValue.length - 1]} {deal.condition[0].condition}
                                            </div>
                                        </div>
                                        <div className='colm2'>
                                            <img className='dealimage' src={deal.image} width={'100px'} height={'100px'} />
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>


            </div>


            {pointRedeem && <CustomsModal
                open={pointRedeem}
                component={
                    <div className='spendingModal'>
                        <div className='head1'>
                            Please confirm the following <br />
                            transaction.
                        </div>

                        <div className=''>
                            <input
                                type="number"
                                name="spending"
                                class="form-control text-field mt-20"
                                id="exampleInputPassword1"
                                placeholder="Enter your redeeming points"
                                onChange={(e) => { setPoints(e.target.value) }}
                            />
                        </div>

                        <div className='amounttext'>
                            <div className='caption'>Points Redeemed: </div>
                            <div className='amount'>{points ? points : '0'}</div>
                        </div>


                        <div className='buttonflex'>
                            <button className='spending' disabled={!points} onClick={pointsRedemmedSubmit} >
                                {/* //onClick={pointsRedemmedSubmit} */}
                                {pointsLoader ?
                                    <div className="spinner-border" role="status">
                                        <span class="sr-only"></span>
                                    </div>
                                    : 'Confirm'}
                            </button>
                            <button className='redeemed' onClick={() => { setPointRedeem(!pointRedeem) }}>
                                Cancel
                            </button>
                        </div>


                    </div>
                }
                onClose={() => { setPointRedeem(!pointRedeem) }}
                minWidth={'498px'}
            />}
        </div>
    )
}

// function PointsRedeemModal({ pointRedeem, setPointRedeem, points, setPoints, pointsRedemmedSubmit, pointsLoader }) {
//     return (

//     )
// }



export default ReadFlowData