import { Environment } from "../enums/enums"

const WALLETLY_DEV_DOMAIN  = 'v2dev.walletly.ai'
const WALLETLY_DEV_DOMAIN2 = 'http://d15lbon6ior0wo.cloudfront.net/'
const WALLETLY_PROD_DOMAIN = 'api.walletly.ai'
const WALLETLY_PROD_DOMAIN2 = 'http://d15lbon6ior0wo.cloudfront.net/'

const env = {
    // STRIPE_PUBLISH_KEY       : 'pk_test_51HSLKJBu7JlObFpah5BFCSrz5ifCtntMQ8kRKeqpcqyk3rkCrmWTaeGeEGXslBVAOFuLir72424Y7dAQL3E3uIpR005JbCAkow', //practice
    LIGHTSPEED_SECRET_KEY   : '',
    LIGHTSPEED_CLIENT_ID    : '',
    API_URL                 : 'https://'+WALLETLY_DEV_DOMAIN+'/api',
    // API_URL                 : 'http://192.168.0.192:8080',
    GOOGLE_MAP_API          : 'AIzaSyBIUUEUoLYKBnVKGvVjLchBzdMR-CUa5A4',
    STRIPE_PUBLISH_KEY      : 'pk_test_51KEu4TAaQTKpqDfVSOxQL2yZmCys5JpWN7pL1wY4hyCWbHE6WyFf22WuzYG48SNfNYO7t63Je7ak95dNmLBk7yhm00jHxqPEmw',   //Testing Development
    AGENCY                  : {default: false, domain: 'myagency.com'},
    ENVIRONMENT             : process.env.REACT_APP_ENV,
    STAMP_COVER_URL         : 'https://dev-walletly.s3.ap-southeast-2.amazonaws.com/StampCover/',
    WALLETLY_USINESS_DOMAIN : '',
    WALLETLY_DOMAIN         : '',
    SOCKET_URL              : '',
    QRCODE_LINK             : '',
    WALLETLY_DOMAIN2        : ''
}
console.log('process.env.REACT_APP_ENV ',process.env.REACT_APP_ENV );

if(process.env.REACT_APP_ENV === Environment.DEVELOPMENT){

    // env.API_URL               = 'http://localhost:8080'
    // env.API_URL               = 'http://192.168.0.192:8080'
    env.SOCKET_URL            = 'http://localhost:8080'
    env.GOOGLE_MAP_API        = 'AIzaSyBIUUEUoLYKBnVKGvVjLchBzdMR-CUa5A4'
    env.STRIPE_PUBLISH_KEY    = 'pk_test_51KEu4TAaQTKpqDfVSOxQL2yZmCys5JpWN7pL1wY4hyCWbHE6WyFf22WuzYG48SNfNYO7t63Je7ak95dNmLBk7yhm00jHxqPEmw'  //Testing Development
    env.AGENCY.default        = true
    env.WALLETLY_DOMAIN       = 'localhost'
    env.LIGHTSPEED_CLIENT_ID  = 'mUOSsQDMLlUuO1Cb'
    env.LIGHTSPEED_SECRET_KEY = 'GyfNSMQMO2MIAfT68iXBcevUSxYSFpcQWSMNHni9'
    env.LIGHTSPEED_SECRET_KEY = 'GyfNSMQMO2MIAfT68iXBcevUSxYSFpcQWSMNHni9'
}

if(process.env.REACT_APP_ENV === Environment.STAGING){

    env.WALLETLY_USINESS_DOMAIN = 'v2devbusiness.walletly.ai'
    env.SOCKET_URL               = 'http://v2dev.walletly.ai'
    env.WALLETLY_DOMAIN          = WALLETLY_DEV_DOMAIN
    env.LIGHTSPEED_CLIENT_ID     = 'mUOSsQDMLlUuO1Cb'
    env.LIGHTSPEED_SECRET_KEY    = 'GyfNSMQMO2MIAfT68iXBcevUSxYSFpcQWSMNHni9'
    env.WALLETLY_DOMAIN2         = WALLETLY_DEV_DOMAIN2
    env.API_URL                  = 'https://'+WALLETLY_DEV_DOMAIN+'/api'
    // if(window.location.hostname !== WALLETLY_DEV_DOMAIN && window.location.hostname !== 'localhost')
    // env.API_URL = 'https://' + window.location.hostname + '/api'

}

//console.log('process.env.REACT_APP_ENV ', process.env.REACT_APP_ENV)

if(process.env.REACT_APP_ENV === Environment.PRODUCTION){
    env.API_URL                  = 'https://api.walletly.ai/api'
    // env.API_URL                  = 'http://192.168.0.192:8080'
    // env.API_URL                  = 'https://jumperswithnumbers.com/api'
    env.SOCKET_URL               = 'http://api.walletly.ai'
    env.WALLETLY_USINESS_DOMAIN = 'business.walletly.ai'
    env.GOOGLE_MAP_API           = 'AIzaSyBIUUEUoLYKBnVKGvVjLchBzdMR-CUa5A4'
    env.STRIPE_PUBLISH_KEY       = 'pk_live_51JT5FFJMg7n62qtJeHEfRaJyWhTCvXoBsPWqm7rO982yio0h5Ezo4XVfO9262zYl5O6imSRA5MeO3upxEWdrKYVc00ZLCGGAlr'
    env.WALLETLY_DOMAIN          = WALLETLY_PROD_DOMAIN
    env.LIGHTSPEED_CLIENT_ID     = 'RSTAfOmL0RykDCbq'
    env.LIGHTSPEED_SECRET_KEY    = 'iaOKQussUTyj2wTuZl3lIgdlrB5rkd3VhoIpK3On'
    env.WALLETLY_DOMAIN2         = WALLETLY_PROD_DOMAIN2
    // if(window.location.hostname !== WALLETLY_PROD_DOMAIN) 
    // env.API_URL  = 'https://' + window.location.hostname + '/api'
    

}

export default env