import localforage from 'localforage';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import CustomModal from '../components/CustomModal';
import { BusinessService } from '../services';

export default function Deal() {

    const location = useLocation();
    const history  = useNavigate();

    const [pass, setPass]   = useState();
    const [brand, setBrand] = useState();

    const [spending, setSpending] = useState(false);
    const [amount, setAmount]     = useState();

    const [loader, setLoader] = useState(false);

    const [success, setSuccess] = useState(false);

    //console.log('location', location)

    const nextWorking = async (response) => {
        if(response?.success){
            const availableFlows = await localforage.getItem('flows');
            let payload = { brandId: response.data.brandId, passId: response.data.passId, flowIds: location.state.payload.flowIds }
            //console.log('payload', payload);
            // const flowData = await BusinessService.GetFlows({ payload });
            // if(flowData.response.success){
            //     for (let index = 0; index < availableFlows.length; index++) {
            //         if ((availableFlows[index].flow.brandId == response.data.brandId) && (availableFlows[index].flow.passId == response.data.passId)) {
            //                     availableFlows[index].flows = flowData?.response?.data
            //                 }
            //                 await localforage.setItem("flows", availableFlows);
            //     }
            // }
        }
    }

    const  handleTransaction = async () => {
        setLoader(true)
        const user = await localforage.getItem('user');

        let payload = {
            amount         : 0,
            passId       : pass._id,
            brandId       : pass.brandId,
            dealId       : location.state.deal.id,
            data: {
                dealOffer      : {
                  name                    : location.state.deal.deal,
                  isFromBusinessConnector : '1',
                  _id                     : location.state.deal.id,
                  image                   : location.state.deal.image,
                  description             : location.state.deal.description,
                  condition               : location.state.deal.condition
                },
            },
            location      : {
              lat     : brand?.location.lat,
              address : brand?.location.address,
              lng     : brand?.location.lng
            },
            removePoints       : (location.state.deal.condition[0].condition === 'point') ? location.state.deal.condition[0].conditionValue[1] : 0,
            type : 'deal',
            platform: 'bc web'
            // amount         : '0',
            // availDealOffer : true,
            // passCode       : pass.passCode,
            // dealOffer      : {
            //   name                    : location.state.deal.deal,
            //   isFromBusinessConnector : '1',
            //   _id                     : location.state.deal.id,
            //   image                   : location.state.deal.image,
            //   description             : location.state.deal.description,
            //   condition               : location.state.deal.condition
            // },
            // discountType  : 1,
            // discountValue : 0,
            // flowId        : location.state.payload.flowIds,
            // idKey         : user.idKey,
            // location      : {
            //   lat     : brand?.location.lat,
            //   address : brand?.location.address,
            //   lng     : brand?.location.lng
            // },
            // pointUsed       : (location.state.deal.condition[0].condition === 'point') ? location.state.deal.condition[0].conditionValue[1] : 0,
            // transactionType : 'giveaway',
        }


        const {error, response} = await BusinessService.BusinessCheckout({payload});

        //console.log('response 22', response);

        nextWorking(response)
        history('/scanner')
        if(response && response.success) {
            setSuccess(true);
        }
    }
    

    const onLoad =  async() =>{
        
        if(location && location?.state && location?.state?.payload){
            const {response, error} = await BusinessService.GetPublicPass({query: {_id: location?.state?.payload?.passId, delete: false}});
            //console.log('response', response);
            if(response && response.success){
                setPass(response.data[0])
            }

            const brand = await BusinessService.GetPublicBrand({query: {_id: location?.state?.payload?.brandId}});
            //console.log('brand' , brand);
            if(brand && brand.response && brand.response.success){
                setBrand(brand.response.data[0]);
            }
        }


    }

    useEffect(() => {
        if(location.state==null){
            history('/scanner', {replace : true})
        }
        else{
            onLoad();
        }
    },[]); 
    

    const handleSpending = async () => {
        setLoader(false)
    }


  return (
    <div>
        { !success ? 
        <>
            <div className='navbar HeadingH24R'>
                <div className='cp' onClick={()=>{history('/scanner')}}>
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.5002 0.516594C21.346 0.362068 21.1629 0.239471 20.9612 0.155823C20.7596 0.0721743 20.5435 0.0291174 20.3252 0.0291174C20.1069 0.0291174 19.8908 0.0721743 19.6891 0.155823C19.4875 0.239471 19.3044 0.362068 19.1502 0.516594L11.0002 8.64993L2.8502 0.499927C2.69602 0.345401 2.51287 0.222805 2.31125 0.139156C2.10963 0.0555081 1.89348 0.0124512 1.6752 0.0124512C1.45691 0.0124512 1.24077 0.0555081 1.03914 0.139156C0.837518 0.222805 0.654374 0.345401 0.500195 0.499927C-0.149805 1.14993 -0.149805 2.19993 0.500195 2.84993L8.6502 10.9999L0.500195 19.1499C-0.149805 19.7999 -0.149805 20.8499 0.500195 21.4999C1.1502 22.1499 2.2002 22.1499 2.8502 21.4999L11.0002 13.3499L19.1502 21.4999C19.8002 22.1499 20.8502 22.1499 21.5002 21.4999C22.1502 20.8499 22.1502 19.7999 21.5002 19.1499L13.3502 10.9999L21.5002 2.84993C22.1335 2.21659 22.1335 1.14993 21.5002 0.516594V0.516594Z" fill="black"/>
                    </svg>
                </div>
            </div>


            <div className='deals mt-20'>
                <div className='headingdeals'>Redeem Deal</div>
                <div className='deal'>
                    <div className='colm1'>
                        <div className='HeadlineH20M'>
                            {location?.state?.deal?.deal}
                        </div>
                        <div className='caption'>
                            {location?.state?.deal?.description}
                        </div>
                    </div>
                    <div className='colm2'>
                        <img className='dealimage' src={location?.state?.deal?.image} width={'100px'} height={'100px'}/>
                    </div>
                </div>

                {spending && <CustomModal
                    open      = {spending}
                    component = {<SpendingModule 
                                    setSpending     = {setSpending}
                                    spending        = {spending}
                                    amount          = {amount}
                                    setAmount       = {setAmount}
                                />}
                    onClose   = {() => {setSpending(!spending)}}
                    minWidth  = {'498px'}
                />}

                
                <div className='spending' onClick={handleSpending}>{'Enter Spending Amount : ' + (amount) ? amount : 0 }</div>


                <div className='user'>
                        <div className='col1'>
                            {pass && (pass.userData.firstName + ' ' + pass.userData.lastName)}
                        </div>

                        <div className='col2'>
                            <div className='caption'>PassCode: <span className='passcode'>{pass && pass.passCode}</span></div>
                        </div>
                </div>


                <div className='buttonflex'>
                    <button className='spending' onClick={handleTransaction}>
                        { loader ? 
                            <div className="spinner-border" role="status">
                                <span class="sr-only"></span>
                            </div>
                        : 'Confirm'}
                    </button>
                    <button className='redeemed' onClick={()=>{history('/scanner')}}>
                        Cancel
                    </button>
                </div>
            </div>

        </>
        : 
        <>
            <div className='navbar HeadingH24R'>
                <div className='cp' onClick={()=>{history('/scanner')}}>
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.5002 0.516594C21.346 0.362068 21.1629 0.239471 20.9612 0.155823C20.7596 0.0721743 20.5435 0.0291174 20.3252 0.0291174C20.1069 0.0291174 19.8908 0.0721743 19.6891 0.155823C19.4875 0.239471 19.3044 0.362068 19.1502 0.516594L11.0002 8.64993L2.8502 0.499927C2.69602 0.345401 2.51287 0.222805 2.31125 0.139156C2.10963 0.0555081 1.89348 0.0124512 1.6752 0.0124512C1.45691 0.0124512 1.24077 0.0555081 1.03914 0.139156C0.837518 0.222805 0.654374 0.345401 0.500195 0.499927C-0.149805 1.14993 -0.149805 2.19993 0.500195 2.84993L8.6502 10.9999L0.500195 19.1499C-0.149805 19.7999 -0.149805 20.8499 0.500195 21.4999C1.1502 22.1499 2.2002 22.1499 2.8502 21.4999L11.0002 13.3499L19.1502 21.4999C19.8002 22.1499 20.8502 22.1499 21.5002 21.4999C22.1502 20.8499 22.1502 19.7999 21.5002 19.1499L13.3502 10.9999L21.5002 2.84993C22.1335 2.21659 22.1335 1.14993 21.5002 0.516594V0.516594Z" fill="black"/>
                    </svg>
                </div>
            </div>


            <div className='deals mt-20'>
                <div className='headingdeals'>Deal Redeemed</div>
                <div className='dealredeemname'>
                    Free Elevated Tea With Smoothie Purchase
                </div>
                <div className='dealredeemimage'>
                    <img className='dealimage' src={location?.state?.deal?.image} width={'100px'} height={'100px'}/>
                </div>

                
                
                <div className='user'>
                        <div className='col1'>
                            {pass && (pass.userData.firstName + ' ' + pass.userData.lastName)}
                        </div>

                        <div className='col2'>
                            <div className='caption'>PassCode: <span className='passcode'>{pass && pass.passCode}</span></div>
                        </div>
                </div>



                    <div className='buttonflex done'>
                        <button className='spending done' onClick={()=>{history('/scanner')}}>
                            Done
                        </button>
                    </div>
            </div>

        </>
        
        }
    </div>
  )
}



function SpendingModule({spending, setSpending, amount, setAmount, spendingSubmit, amountLoader}) {
    return(
        <div className='spendingModal'>
             <div className='head1'>
                Please confirm the following <br/>
                transaction.
             </div>

             <div className=''>
                <input
                    type        = "number"
                    name        = "spending"
                    class       = "form-control text-field mt-20"
                    id          = "exampleInputPassword1"
                    placeholder = "Enter your Spending amount"
                    onChange    = {(e)=>{setAmount(e.target.value)}}
                />
             </div>

             <div className='amounttext'> 
                <div className='caption'>Amount Spent: </div>
                <div className='amount'>{amount ? amount : '0'}</div>
             </div>


             <div className='buttonflex'>
                <button className='spending' disabled={!amount} onClick={()=>{setSpending(!spending)}}>
                   {'Confirm'}
                </button>
                <button className='redeemed' onClick={()=>{setSpending(!spending); setAmount(0)}}>
                    Cancel
                </button>
            </div>



        </div>
    )
}
