import localforage from 'localforage';
import React, { useEffect, useState } from 'react'
import { redirect, useLocation, useNavigate } from 'react-router-dom'
import CustomsModal from '../components/CustomModal';
import { BusinessService } from '../services';

export default function FlowData(props) {
    const location = useLocation();
    const history  = useNavigate();

    const [pass, setPass]               = useState();
    const [deals, setDeals]             = useState();
    const [spending, setSpending]       = useState(false);
    const [pointRedeem, setPointRedeem] = useState(false);
    const [amount, setAmount] = useState();
    const [amountLoader, setAmountLoader] = useState(false);
    const [points, setPoints]             = useState();
    const [pointsLoader, setPointsLoader] = useState(false);
    const [success, setSuccess] = useState(false);
    const [dataloader, setDataLoader] = useState(false);
    const onLoad =  async() =>{
        //console.log('location pool', location);
        if(!location.state.pass){
            const {response, error} = await BusinessService.GetPublicPass({query: {_id: location.state.payload.passId, delete: false}});
            //console.log('response payLoad', response);
            if(response && response.success){
                setPass(response.data[0])
            }
        }else{
            setPass(location?.state?.pass)
        }

        if(location.state.deal){
            setDeals(location.state.deal)
            setPass(location.state.pass)

        }else{
            setDataLoader(true);
        if(location && location.state && location.state.payload){

            
    
            let payload = {brandId: location.state.payload.brandId, passId: location.state.payload.passId, flowIds: location.state.payload.flowIds}
            //console.log('payload', payload);
            // const flowData = await BusinessService.GetFlows({payload});
            // //console.log(flowData);

            // if(flowData.response && flowData.response.success) {
            //     setDeals(flowData.response.data);

            //     setDataLoader(false);
            // }
            setDataLoader(false);
        } 
        }
        
       
    }
    
    useEffect(() => {
        if(location.state==null){
            history('/scanner', {replace : true})
        }
        else{
            onLoad();
        }
    },[]); 
    //console.log('Pdddd',pass);
    
    

    let customerDetail = [
        {
            'label' : 'Name',
            'value': pass && pass.userData.firstName  + ' ' + pass.userData.lastName
        },
        {
            'label' : 'Points',
            'value' : pass && pass[pass.passType].currentPoints
        },
        {
            'label' : 'Passcode',
            'value' : pass && pass.passCode
        }

    ]

    console.log('location ', location.state)

    const handleSpending = () => {
        // setSpending(true);
        history('/spend', { state: { pass: location.state.pass , payload : location.state.payload  } })
    }

    const handlePointsRedeem = () => {
        setPointRedeem(true);
    }


    const spendingSubmit = async () => {
        setAmountLoader(true);
        let user = await localforage.getItem('user');
        let payload = {
            // amount          : amount,
            // discountType    : 1,
            // discountValue   : 0,
            // flowId          : location.state.payload.flowIds,
            // idKey           : user.idKey,
            // passCode        : pass.passCode,
            // transactionType : 'amount',
            amount          : amount,
            passId        : pass._id,
            brandId        : pass.brandId,
            type : 'amount',
            platform : 'bc web',
        }

        const {error, response} = await BusinessService.BusinessCheckout({payload})

        if(response && response.success) {
            setSuccess(true);
            setSpending(false);
        }
        
        setAmountLoader(false);

    }


    const pointsRedemmedSubmit = async () => {
        setPointsLoader(true);
        let payload = {
            //  location: {
            //    address: 'Karachi, Pakistan',
            //    lat: 24.91123372672892,
            //    lng: 67.08654837127668
            //  },
            //  brandId  : pass.brandId,
            //  passId   : pass._id,
            //  subtract : true,
            //  points   : points

            passId: pass._id,
            brandId: pass.brandId,
            // location: {
            //     lat: brand?.location.lat,
            //     address: brand?.location.address,
            //     lng: brand?.location.lng
            // },
            removePoints: points,
            type : 'redeem points',
            platform : 'bc web'
        }

        // const {response, error} = await BusinessService.SingleLoyalitySelf({payload});
        const {response, error} = await BusinessService.BusinessCheckout({payload});

        if(response && response.success){ 
            setSuccess(true);
            setPointsLoader(false);
        }

        setPointRedeem(false);

    }


    const handleDealSelect = (deal) =>{
        let payload = {
            ...location.state.payload
        }
        history('/deal', {state: {payload: payload, deal: deal}})
    }

  return (
    <div>
        <div className='navbar HeadingH24R'>
            <div className='cp' onClick={()=>{history('/scanner')}}>
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.5002 0.516594C21.346 0.362068 21.1629 0.239471 20.9612 0.155823C20.7596 0.0721743 20.5435 0.0291174 20.3252 0.0291174C20.1069 0.0291174 19.8908 0.0721743 19.6891 0.155823C19.4875 0.239471 19.3044 0.362068 19.1502 0.516594L11.0002 8.64993L2.8502 0.499927C2.69602 0.345401 2.51287 0.222805 2.31125 0.139156C2.10963 0.0555081 1.89348 0.0124512 1.6752 0.0124512C1.45691 0.0124512 1.24077 0.0555081 1.03914 0.139156C0.837518 0.222805 0.654374 0.345401 0.500195 0.499927C-0.149805 1.14993 -0.149805 2.19993 0.500195 2.84993L8.6502 10.9999L0.500195 19.1499C-0.149805 19.7999 -0.149805 20.8499 0.500195 21.4999C1.1502 22.1499 2.2002 22.1499 2.8502 21.4999L11.0002 13.3499L19.1502 21.4999C19.8002 22.1499 20.8502 22.1499 21.5002 21.4999C22.1502 20.8499 22.1502 19.7999 21.5002 19.1499L13.3502 10.9999L21.5002 2.84993C22.1335 2.21659 22.1335 1.14993 21.5002 0.516594V0.516594Z" fill="black"/>
                </svg>
            </div>

            <div className='text-center res-cus-head'>
                Customer Details
            </div>

        </div>


        {spending && <CustomsModal
            open      = {spending}
            component = {<SpendingModule 
                            setSpending     = {setSpending}
                            spending        = {spending}
                            amount          = {amount}
                            setAmount       = {setAmount}
                            spendingSubmit  = {spendingSubmit}
                            setAmountLoader = {setAmountLoader}
                            amountLoader    = {amountLoader}
                        />}
            onClose   = {() => {setSpending(!spending)}}
            minWidth  = {'498px'}
        />}


        {pointRedeem && <CustomsModal
            open      = {pointRedeem}
            component = {<PointsRedeemModal 
                            setPointRedeem       = {setPointRedeem}
                            pointRedeem          = {pointRedeem}
                            points               = {points}
                            setPoints            = {setPoints}
                            pointsRedemmedSubmit = {pointsRedemmedSubmit}
                            setPointsLoader      = {setPointsLoader}
                            pointsLoader         = {pointsLoader}
                        />}
            onClose   = {() => {setPointRedeem(!pointRedeem)}}
            minWidth  = {'498px'}
        />}


        {
            success && <CustomsModal
                    open      = {success}
                    component = {<SuccessModal 
                                    success    = {success}
                                    setSuccess = {setSuccess}
                                />}
                    onClose   = {() => {setSuccess(!success)}}
                    minWidth  = {'498px'}
                />
        }


        <div className='customerdetail'>
                {
                    customerDetail.map((element, idx)=>(
                        <div className={idx!== 0 ? 'mt-20 box res-cus-info' : 'box res-cus-info'}>
                            <div className='w-10 key capitalize'>{element.label}</div>
                            <div className='pl-50  value capitalize'>{`${element.value} ${idx == 1 ? 'Points' : ''} `}</div>
                        </div>
                    ))
                }
        </div>

        <div className='flow'>
            <div className='buttonflex'>
                <button className='spending' onClick={handleSpending}>
                    Enter Spending
                </button>
                <button className='redeemed' onClick={handlePointsRedeem}>
                    Points Redeem
                </button>
            </div>
        </div>


    {dataloader ? 
        <div className='loader'>
                <div className="spinner-border" role="status">
                    <span class="sr-only"></span>
                </div>
        </div>
      
      : 
        <div className='deals mt-50'>
            <div className='headingdeals text-left'>{deals && deals.length > 0 ? "Available Deals" : "No Deals Available"}</div> 
                {
                    deals && deals.length > 0 && deals.map((deal, idx)=>(
                            <div className={!deal.valid ? 'deal invalid' : 'deal'} onClick={()=>{handleDealSelect(deal)}}>
                                <div className='colm1'>
                                    <div className='HeadlineH20M'>
                                        {deal.deal}
                                    </div>
                                    <div className='caption'>
                                        {deal.description}
                                    </div>
                                    <div className='condition'>
                                        {deal.condition[0].conditionValue[1] ? deal.condition[0].conditionValue[1] + ' ' + deal.condition[0].condition : deal.condition[0].condition}
                                    </div>
                                </div>
                                <div className='colm2'>
                                    <img className='dealimage' src={deal.image} width={'100px'} height={'100px'}/>
                                </div>
                            </div>
                    ))
                }
        </div>
    }
    </div>
  )
}



function SpendingModule({spending, setSpending, amount, setAmount, spendingSubmit, amountLoader}) {
    return(
        <div className='spendingModal'>
             <div className='head1'>
                Please confirm the following <br/>
                transaction.
             </div>

             <div className=''>
                <input
                    type        = "number"
                    name        = "spending"
                    class       = "form-control text-field mt-20"
                    id          = "exampleInputPassword1"
                    placeholder = "Enter your Spending amount"
                    onChange    = {(e)=>{setAmount(e.target.value)}}
                />
             </div>

             <div className='amounttext'> 
                <div className='caption'>Amount Spent: </div>
                <div className='amount'>{amount ? amount : '0'}</div>
             </div>


             <div className='buttonflex'>
                <button className='spending' disabled={!amount} onClick={spendingSubmit}>
                   {amountLoader ? 
                        <div className="spinner-border" role="status">
                            <span class="sr-only"></span>
                        </div>
                    :  'Confirm'}
                </button>
                <button className='redeemed' onClick={()=>{setSpending(!spending)}}>
                    Cancel
                </button>
            </div>



        </div>
    )
}




function PointsRedeemModal({pointRedeem, setPointRedeem, points, setPoints, pointsRedemmedSubmit, pointsLoader}) {
    return(
        <div className='spendingModal'>
             <div className='head1'>
                Please confirm the following <br/>
                transaction.
             </div>

             <div className=''>
                <input
                    type        = "number"
                    name        = "spending"
                    class       = "form-control text-field mt-20"
                    id          = "exampleInputPassword1"
                    placeholder = "Enter your redeeming points"
                    onChange    = {(e)=>{setPoints(e.target.value)}}
                />
             </div>

             <div className='amounttext'> 
                <div className='caption'>Points Redeemed: </div>
                <div className='amount'>{points ? points : '0'}</div>
             </div>


             <div className='buttonflex'>
                <button className='spending' disabled={!points} onClick={pointsRedemmedSubmit}>
                   {pointsLoader ? 
                        <div className="spinner-border" role="status">
                            <span class="sr-only"></span>
                        </div>
                    :  'Confirm'}
                </button>
                <button className='redeemed' onClick={()=>{setPointRedeem(!pointRedeem)}}>
                    Cancel
                </button>
            </div>



        </div>
    )
}



function SuccessModal({success, setSuccess}){
    return(
        <div className='spendingModal'>
                <div className='right'>
                    <svg width="78" height="78" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M44 0C19.8 0 0 19.8 0 44C0 68.2 19.8 88 44 88C68.2 88 88 68.2 88 44C88 19.8 68.2 0 44 0ZM64.9 35.5667L41.0667 57.5667C39.2333 59.0333 36.6667 59.0333 35.2 57.5667L23.4667 46.9333C22 45.4667 22 43.2667 23.1 41.8C24.2 40.3333 26.7667 40.3333 28.2333 41.4333L38.1333 50.2333L60.1333 30.0667C61.6 28.6 63.8 28.9667 65.2667 30.4333C66.7333 31.9 66.3667 34.4667 64.9 35.5667Z" fill="#1059FA"/>
                    </svg>
                </div>

                <div className='headsuccess'>Successfully Rewarded</div>


                <button className='successBtn' onClick={()=>{setSuccess(!success)}}>
                    Done
                </button>
        </div>
    )
}