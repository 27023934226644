import './qrcomponent.scss'
import PngIcons from '../assets/images/pngIcons'
import { useState, useEffect } from 'react'
import { QrReader } from 'react-qr-reader'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import {BusinessService} from '../services'

export default function QrComponent () {
  const [data, setData] = useState()
  const [showCamera, setShowCamera] = useState(false)
  const [load, setLoad] = useState(false)
  const [error, setError] = useState('')

  const navigate = useNavigate()

  useEffect(() => {
    //console.log('data ', data, data?.split('&'))

    if (data) postData(data)
  }, [data])

  async function postData (data) {
    console.log('data',data);
    setLoad(true);
    setShowCamera(false);

    function getQueryParam(url, param) {
      const queryString = url.split('?')[1];
      if (queryString) {
          const params = new URLSearchParams(queryString);
          return params.get(param);
      } else {
          return null;
      }
  }

  const passId = getQueryParam(data, 'passId');
const flowId = getQueryParam(data, 'flowId');
const brandId = getQueryParam(data, 'brandId');

console.log("passId:", passId);
console.log("flowId:", flowId);
console.log("brandId:", brandId);

    const payload = {
      passId: getQueryParam(data, 'passId'),
      brandId: getQueryParam(data, 'flowId'),
      flowIds: [getQueryParam(data, 'brandId')]
      // passId: data?.split('&')[1].split('=')[1],
      // brandId: data?.split('&')[0].split('=')[1],
      // flowIds: [data?.split('&')[3].split('=')[1]]
    }

    //console.log('payload ', payload)

    const uniqueId    = data?.split('&')[4].split('=')[1]
    const uniqueIdKey = data?.split('&')[4].split('=')[0]
    //console.log('uniqueId', uniqueId);
    let qrSuccess;

    const pass = await BusinessService.GetPublicPass({ query: { _id: payload.passId, delete: false } });

    console.log('uniqueIdKey',uniqueIdKey);
    if (uniqueId && uniqueIdKey === 'dealUniqueId') {
      //console.log('1');
      // const {response, error} = await BusinessService.GetFlows({payload})
      // if(response.success && response.data && response.data.length > 0){
      //   let deal = response.data.filter((element, idx)=>{
      //     if(element.uniqueId === uniqueId) return true;
      //   })

      //   //console.log('deal',deal);
      //   if(deal[0].valid){
      //     navigate('/spend', {state: {payload ,pass: pass?.response.data[0], url: data, deal: deal[0]}});
      //   }
      // }
      setShowCamera(true)
      setLoad(false)
    } else {
      //console.log('2');
      navigate('/spend', {state : {payload ,pass: pass?.response.data[0], url: data}})
    }
  }

  return (
    <div className='bg2 mt-50 for-mtzero'>
      {load && (
        <div className='d-flex justify-content-center'>
          <div
            className='spinner-border text-light spinner mt-70'
            role='status'
          >
            <span className='sr-only'></span>
          </div>
        </div>
      )}
      {showCamera ? (
        <div className='qrReader'>
          <QrReader
            key="environment"
            // constraints={{
            //   audio: false,
            //   video: { facingMode: "environment" }}
            // }
            constraints={ {facingMode: 'environment'} }
            facingMode="rear"
            onResult={(result, error) => {
              if (!!result) {
                setData(result?.text)
              }

              if (!!error) {
                // console.info(error);
              }
            }}
            style={{ width: '500px !important', height: '500px !important' }}
          />
        </div>
      ) : (

        !load && <div className='mt-50 for-mtzero'>
          <img
            onClick={() => setShowCamera(true)}
            className='mt-100 logo cp'
            alt=''
            src={PngIcons.Qr}
          />

          <p className='headline mt-20'>Press to Scan QR Code</p>
          <p>{error}</p>
        </div>
      )}
    </div>
  )
}
